import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import SwapIndicator from '../../components/elements/SwapIndicator'
import Hex from '../models/Hex';
import HexText from './HexText';
import HexUtils from '../HexUtils';
import Board from '../../models/Board';
import utils  from '../../utils.js';
import theme from '../../theme.style.js';

export default class Tile extends Component {
  static propTypes = {
    q: PropTypes.number.isRequired,
    r: PropTypes.number.isRequired,
    s: PropTypes.number.isRequired,
    letter: PropTypes.string,
    fill: PropTypes.string,
    isSelected: PropTypes.bool,
    className: PropTypes.string,
    data: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node
  };

  static contextTypes = {
    layout: PropTypes.object, // TODO Shape
    points: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);
    const { q, r, s, letter, isSelected, isPerm, tileColor } = props;
    const hex = new Hex(q, r, s, letter, isSelected, isPerm, tileColor);
    const { layout } = context;
    const pixel = HexUtils.hexToPixel(hex, layout);


    this.state = {
      hex,
      pixel,
      dragging: false,
      offsetTop: 0,
      offsetLeft: 0,
      dragTargetTiles: [],
      toSwapTiles: [],
      lastLetter: props.letter
    };

    // animation vars
    this.animatingColor = false;
    this.lastFill = utils.hexToRgbA(this.assignTileColor(props.tileOwner, props.isPerm));
    this.currFill = this.lastFill;
    this.transitionDuration = 400;
  }

  componentWillMount() {

  }

  // TODO Refactor to reduce duplicate
  //componentWillReceiveProps(nextProps) {
    // const { q, r, s } = nextProps;
    // const { layout } = this.context;
    // const hex = new Hex(q, r, s);
    // const pixel = HexUtils.hexToPixel(hex, layout);
    // this.setState({ hex, pixel });
  onClick() {
    const { addTile, removeTile } = this.props;
    const { clickedTiles, lastTileHex } = this.props;
    const { isSelected, isHighlighted, tileOwner, isPerm } = this.props;
    const { playerCurrent } = this.props;
    const { hex } = this.state;

    let position = Board.positionToString(hex);
    if (tileOwner === 'neutral' ||
       (tileOwner === playerCurrent)) {
      if (!isSelected) {
        if (clickedTiles.length === 0 ||
            this.tileIsAdjacent(hex, lastTileHex)) {
          addTile(position, isHighlighted);
        }
      }
      else if (hex.equals(lastTileHex)) {
        removeTile();
      }
    }
  }

  tileIsAdjacent(hex, otherTile) {
    return (HexUtils.distance(hex, otherTile) === 1);
  }

  assignTileColor(tileOwner, isPerm) {
    const { userID, disabled } = this.props;

    if (tileOwner === 'neutral') {
      return (disabled ? 'none' : theme.GRAY_TILE);
    }
    else if (tileOwner === userID) {
      return (isPerm ? theme.BLUE_DARK : theme.BLUE_LIGHT);
    }
    else {
      return (isPerm ? theme.RED_DARK : theme.RED_LIGHT);
    }
  }

  assignStrokeColor() {
    const { isSelected, isHighlighted } = this.props;

    if (isSelected) {
      return theme.BLACK_ALMOST;
    } else if (isHighlighted) {
      return theme.BLUE_DARK;
    } else {
      return 'none'
    }
  }

  shouldComponentUpdate(nextProps, nextState){
    return (this.props.isSelected !== nextProps.isSelected ||
            this.props.isPerm !== nextProps.isPerm ||
            this.props.isHighlighted !== nextProps.isHighlighted ||
            this.props.tileOwner !== nextProps.tileOwner ||
            this.props.disabled !== nextProps.disabled ||
            this.props.letter !== nextProps.letter ||
            this.props.opacity !== nextProps.opacity ||
            this.props.playerCurrent !== nextProps.playerCurrent ||
            this.props.permClear !== nextProps.permClear ||
            this.props.shouldAnimate !== nextProps.shouldAnimate ||
            this.state.lastLetter !== nextState.lastLetter ||
            this.state.offsetLeft !== nextState.offsetLeft ||
            this.state.offsetTop !== nextState.offsetTop ||
            this.state.dragTargetTiles.length !== nextState.dragTargetTiles.length ||
            this.state.toSwapTiles.length !== nextState.toSwapTiles.length);
  }

  componentDidUpdate(prevProps) {
    const { tileOwner, isPerm, disabled, shouldAnimate, letter, permClear } = this.props;
    let newColor = utils.hexToRgbA(this.assignTileColor(tileOwner, isPerm));

    // Animate tile color only when in game and game has determined shouldAnimate
    //   Also override and animate permClear if set
    if (!this.animatingColor) {
      if (newColor !== this.lastFill) {
        if (disabled || !shouldAnimate) {
          this.lastFill = newColor;
          this.currFill = newColor;
        } else {
          if (permClear !== null) {
            this.animatePermClear();
          } else {
            this.animateTileColor(newColor);
          }
        }
      }
    }

    // Animate letter change
    if (prevProps.letter !== letter) {
      if (disabled || !shouldAnimate) {
        this.setState({ lastLetter: letter});;
      } else {
        this.animateTileLetter(letter);
        if (permClear !== null && !this.animatingColor) {
          this.animatePermClear();
        }
      }
    }
  }

  // The touch polygon is an invisible polygon that handles all the touch interactions
  renderTouchPolygon() {
    const { disabled, playerCurrent, userID, tileOwner, isPerm } = this.props;
    const { points } = this.context;
    const { pixel } = this.state;

    if (disabled || // Don't render if not in specific Game
        playerCurrent !== userID || // Don't render if not your turn
        (isPerm && tileOwner !== userID)) { // Don't render if opponent's perm tile
      return null;
    }

    let touchPolygon = (
      <polygon
        key={`${pixel.x}${pixel.y}-touch`}
        x={pixel.x}
        y={pixel.y}
        {...this.panResponder.panHandlers}
        points={points}
        fill={theme.WHITE}
        fillOpacity={0}
      />
    )
    return touchPolygon;
  }

  render() {
    const { fill, className, isSelected, isHighlighted, disabled, letter, playerCurrent, userID, tileOwner, isPerm, opacity, shouldAnimate } = this.props;
    const { points, layout } = this.context;
    const { hex, pixel, offsetLeft, offsetTop, toSwapTiles, opacityValue, lastLetter } = this.state;
    // const fillId = (fill) ? `url(#${fill})` : null; For pictures?

    // console.log("RENDER TILE");
    let swapIndicator = null;
    let fillOpacity = opacity !== undefined ? opacity : 1;
    let hexText = (<HexText x={pixel.x + offsetLeft} y={pixel.y + offsetTop}>
      {disabled || !shouldAnimate ? letter : lastLetter}
    </HexText>);

    if (disabled) {
      hexText = null;
    }

    if (toSwapTiles.length >= 2) {
      let swapTilePos1 = Board.positionToString(toSwapTiles[0]);
      let swapTilePos2 = Board.positionToString(toSwapTiles[1]);
//      swapIndicator = <SwapIndicator key="drag-swap-indicator" swapTiles={[swapTilePos1, swapTilePos2]} />;
    }

    return [
      <polygon
        key={`${pixel.x}${pixel.y}-hex`}
        transform={`translate(${pixel.x + offsetLeft} ${pixel.y + offsetTop})`}
        ref={ref => (this.polyRef = ref)}
        points={points}
        fill={disabled || !shouldAnimate ? this.assignTileColor(tileOwner, isPerm) : this.currFill}
        fillOpacity={fillOpacity}
        stroke={this.assignStrokeColor()}
        strokeWidth={(isSelected || isHighlighted) ? .6 : 0}
      />,
      <g
        key={`${pixel.x}${pixel.y}-wrapper`}
        fillOpacity={opacity !== undefined ? opacity : opacityValue}
      >
        {hexText}
      </g>,
      this.renderTouchPolygon(),
      swapIndicator
    ]
  }
}

