import React, { Component } from 'react';

export default class Ghost extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="60" cy="60" r="60" fill="#8C6EE3"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <path d="M91.2287 90.8668C94.4715 84.6774 96.0929 21 59.3477 21C24.0723 21 19.6629 87.5059 27.0119 91.8432C34.361 96.1806 33.085 83.1686 38.7704 83.1686C45.8039 83.1686 40.7975 89.9672 47.6209 92.6582C56.7058 96.2411 51.9987 83.1686 57.8779 83.1686C63.7571 83.1686 64.1348 96.9791 69.4248 94.4497C74.8758 91.8432 72.6305 83.1686 78.5097 83.1686C84.389 83.1686 87.9858 97.0561 91.2287 90.8668Z" fill="white"/>
        <circle cx="45" cy="54" r="6" fill="#FFB2B2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M52.5 48C54.9853 48 57 45.3137 57 42C57 41.0107 56.8204 40.0773 56.5023 39.2546C56.0899 39.7092 55.4582 40 54.75 40C53.5074 40 52.5 39.1046 52.5 38C52.5 37.2261 52.9945 36.5549 53.7179 36.2223C53.3306 36.0775 52.9221 36 52.5 36C50.0147 36 48 38.6863 48 42C48 45.3137 50.0147 48 52.5 48Z" fill="#232323"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M67.5 48C69.9853 48 72 45.3137 72 42C72 41.0107 71.8204 40.0773 71.5023 39.2546C71.0899 39.7092 70.4582 40 69.75 40C68.5074 40 67.5 39.1046 67.5 38C67.5 37.2261 67.9945 36.5549 68.7179 36.2223C68.3306 36.0775 67.9221 36 67.5 36C65.0147 36 63 38.6863 63 42C63 45.3137 65.0147 48 67.5 48Z" fill="#232323"/>
        <circle cx="75" cy="54" r="6" fill="#FFB2B2"/>
      </svg>
    );
  }
}