import React, { Component } from 'react';

export default class Cookie extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 80;
    let circleBg = <circle cx="40" cy="40" r="40" fill="#FF7171"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <path fillRule="evenodd" clipRule="evenodd" d="M22.2616 46.2896C27.9809 57.6938 41.7473 62.6191 53.4554 57.2855C65.44 51.826 70.7297 37.6847 65.2701 25.7C59.8106 13.7154 45.6693 8.42579 33.6847 13.8853C33.0402 14.1789 32.4151 14.4976 31.8099 14.8397C32.8761 15.454 33.8089 16.4588 34.3986 17.7531C34.8307 18.7017 35.0245 19.6874 35.0042 20.6217C35.7223 21.2195 36.3388 22.0126 36.7709 22.9611C37.5477 24.6664 37.5543 26.4914 36.9314 27.9224C36.9594 27.9779 36.9863 28.0341 37.0123 28.0911C37.9805 30.2165 37.2584 32.6809 35.4114 33.9733C35.9567 36.1606 34.8841 38.4938 32.7588 39.4619C32.2161 39.7092 31.6513 39.8462 31.0895 39.8824C30.8722 41.4457 29.8842 42.8676 28.3416 43.5703C27.5513 43.9304 26.7141 44.0567 25.9077 43.9783C25.1821 44.755 24.2406 45.4393 23.1335 45.9436C22.8441 46.0754 22.5529 46.1906 22.2616 46.2896Z" fill="#E0AF66"/>
        <ellipse cx="40.2521" cy="35.325" rx="2.16779" ry="3.25168" transform="rotate(41.5942 40.2521 35.325)" fill="#7A5800"/>
        <ellipse cx="43.2196" cy="45.2733" rx="2.16779" ry="2.16779" transform="rotate(-24.4914 43.2196 45.2733)" fill="#7A5800"/>
        <ellipse cx="31.3831" cy="50.6659" rx="2.16779" ry="2.16779" transform="rotate(-24.4914 31.3831 50.6659)" fill="#7A5800"/>
        <ellipse cx="51.4613" cy="31.9911" rx="2.16779" ry="2.16779" transform="rotate(-24.4914 51.4613 31.9911)" fill="#7A5800"/>
        <ellipse cx="42.7485" cy="18.0936" rx="2.16779" ry="3.25168" transform="rotate(-24.4914 42.7485 18.0936)" fill="#7A5800"/>
        <ellipse cx="55.8911" cy="22.3487" rx="2.16779" ry="3.25168" transform="rotate(-61.715 55.8911 22.3487)" fill="#7A5800"/>
        <ellipse cx="56.4037" cy="42.8407" rx="2.16779" ry="3.25168" transform="rotate(-24.4914 56.4037 42.8407)" fill="#7A5800"/>
        <ellipse cx="48.851" cy="53.5375" rx="2.16779" ry="3.25168" transform="rotate(40.1553 48.851 53.5375)" fill="#7A5800"/>
        <ellipse cx="64.0211" cy="33.4159" rx="2.16779" ry="2.16779" transform="rotate(-24.4914 64.0211 33.4159)" fill="#7A5800"/>
        <path d="M21.3144 58.4534C25.2155 59.5501 24.0235 61.1952 22.9399 61.8806C20.2935 63.443 14.9068 65.6158 14.0529 62.1372C14.1058 58.9667 16.4381 57.0825 21.3144 58.4534Z" fill="#E0AF66"/>
        <path d="M27.0742 65.6977C25.5138 64.9666 25.9906 63.8699 26.424 63.4129C27.4826 62.3714 29.6373 60.9228 29.9788 63.2418C29.9577 65.3555 29.0247 66.6116 27.0742 65.6977Z" fill="#E0AF66"/>
        <path d="M54.5371 67.8489C53.7569 67.4833 53.9953 66.9349 54.212 66.7065C54.7413 66.1857 55.8186 65.4614 55.9894 66.6209C55.9788 67.6778 55.5124 68.3058 54.5371 67.8489Z" fill="#E0AF66"/>
        <path d="M36.569 64.9422C38.1499 65.0423 37.9025 65.8928 37.5812 66.3055C36.7832 67.2638 35.0391 68.7663 34.3077 67.3034C33.95 65.8874 34.5929 64.8171 36.569 64.9422Z" fill="#E0AF66"/>
        <path d="M31.4629 67.8489C32.2431 67.4833 32.0047 66.9349 31.788 66.7065C31.2587 66.1857 30.1814 65.4614 30.0106 66.6209C30.0212 67.6778 30.4876 68.3058 31.4629 67.8489Z" fill="#7A5800"/>
        <circle cx="19.3246" cy="61.3246" r="1" transform="rotate(-24.4914 19.3246 61.3246)" fill="#7A5800"/>
      </svg>
    );
  }
}