const flexCenter = {
  alignItems: 'center',
  justifyContent: 'center'
};

export default {
  // BLUE_LIGHT: '#9BDFDC',
  // BLUE_DARK: '#00CCC5',
  // RED_LIGHT: '#FFA3A3',
  // RED_DARK: '#FF5C5C',

  BLUE_LIGHT: '#A0E8E6',
  BLUE_DARK: '#01C2BB',
  RED_LIGHT: '#FFB2B2',
  RED_DARK: '#FF7171',

  GRAY_BG: '#EFEFEF',
  GRAY_TILE: '#DEDEDE',
  GRAY_DARK: '#9B9B9B',
  GRAY_LIGHTEST: '#E6E6E6',

  BLACK_ALMOST: '#232323',
  BLACK: '#000',
  WHITE: '#FFFFFF',

  FONT_SIZE_MENU: 18,
  FONT_SIZE_REGULAR: 14,
  FONT_SIZE_SMALL: 12,

  flexCenter: flexCenter,
  whiteHeaderText: {
    marginTop: 60,
    color: '#fff',
    fontSize: 24,
    fontWeight: '500',
    lineHeight: 29,
  },
  topBarBack: {
    position: 'absolute',
    left: 20
  },
  topBarText: {
    fontSize: 18,
    fontWeight: '500'
  },
  searchInput: {
    height: 40,
    backgroundColor: '#FFF',
    paddingLeft: 20,
    paddingRight: 20
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1
  },
}