import React from 'react';
import './Replay.css';
import firebase from 'firebase';
import Avatar from './components/Avatar';
import ReplayWords from './components/ReplayWords';
import ReplayTurn from './models/ReplayTurn';
import LeftArrow from './assets/LeftArrow';
import RightArrow from './assets/RightArrow';
import Crown from './assets/Crown';
import { forwardReplay, backwardReplay, findTurnIndex } from './ReplayUtils';
import { HexGrid, HexLayout, Tile, HexText, Hex, HexUtils} from './HexGrid';
import theme from './theme.style.js';
import utils from './utils.js';

export default class Replay extends React.Component {
  constructor(props) {
    super();

    this.state = {
      // Database Variables
      board: null,
      userID: null,
      playerOne: null,
      playerTwo: null,
      playerOneScore: 0,
      playerTwoScore: 0,
      scoreToWin: 0,
      isCompleted: false,
      isTied: false,
      isAIGame: false,
      winner: null,
      replay: null,
      rematched: false, //needs to be added to DB
      gameType: null,

      // Local Variables
      currTurnWord: '',
      currTurnIndex: 0,
      movesLeft: 0,
      movesLeftNext: 0,
      wordList: null,
      currWord: '',
      submitWord: '',
      lastTile: null,
      clickedTiles: [],
      swapTiles: [],
      invalidWord: false,
      draggingTile: null,
      suppressEndgameView: false,
      playerOneScoreGain: 0,
      playerTwoScoreGain: 0,
      chatImage: null,
      chatMessage: '',

      playerOneData: null,
      playerTwoData: null,

      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < 600
    }
  }

  componentDidMount() {
    const { gameID, flipFirstPlayer, turnIndex } = this.props.match.params;
    console.log("MOUNT", gameID, turnIndex);
    var gameRef = firebase.firestore().collection('games').doc(gameID);

    gameRef.get().then((doc) => {
      if (doc.exists) {
        let data = doc.data();
        console.log("Document data:", data, flipFirstPlayer);

        let playerOneRef = data.playerOne && firebase.firestore().collection('users').doc(data.playerOne);
        if (playerOneRef) {
          playerOneRef.get().then((doc) => {
            this.setState({ playerOneData: doc.data() });
          });
        }
        let playerTwoRef = data.playerTwo && firebase.firestore().collection('users').doc(data.playerTwo);
        if (playerTwoRef) {
          playerTwoRef.get().then((doc) => {
            this.setState({ playerTwoData: doc.data() });
          });
        }

        this.updateGameState(data);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });

    // Setup handlers
    this.throttledWindowResize = utils.throttle(this.handleWindowScroll, 200, {context: this});
    window.addEventListener('resize', this.throttledWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledWindowResize);
  }


  updateGameState(gameData) {
    const { turnIndex } = this.props.match.params;

    // Update replay num moves
    let turnIndices = [];
    this.replayNumMoves = 0;
    if (gameData.replay && gameData.replay.turns && gameData.replay.turns.length) {
      this.replayCachedMoves = {};
      for (let i = 0; i < gameData.replay.turns.length; i++) {
        this.replayNumMoves += gameData.replay.turns[i].moves.length + 1;
        turnIndices.push(this.replayNumMoves);
      }
      this.moveIndex = this.replayNumMoves;
      this.lastMoveIndex = this.moveIndex;
    }

    this.setState({
      board: gameData.board,
      userID: gameData.playerOne,
      playerCurrent: gameData.playerCurrent,
      playerOne: gameData.playerOne,
      playerTwo: gameData.playerTwo,
      playerOneScore: gameData.playerOneScore,
      playerTwoScore: gameData.playerTwoScore,
      scoreToWin: gameData.scoreToWin,
      isCompleted: gameData.isCompleted,
      isTied: gameData.isTied,
      isAIGame: gameData.isAIGame,
      winner: gameData.winner,
      replay: gameData.replay,
      gameType: gameData.gameType,
      turnIndices: turnIndices
    }, () => {
      // Set replay to beginning
      this.handleReplayChange(0, true, () => {
        if (!!turnIndex) {
          // Calculate left and right arrow replay indices (magic, unexplainable)
          let movesLeft = this.replayNumMoves;
          let movesLeftNext = this.replayNumMoves;
          if (gameData.replay && gameData.replay.turns && gameData.replay.turns.length) {
            for (var k = gameData.replay.turns.length - 1; k >= 0; k--) {
              let turn = gameData.replay.turns[k];
              if (turnIndices[turnIndex-1] > movesLeft) {
                break;
              } else if (this.moveIndex === movesLeft) {
                movesLeft -= 1;
                break;
              } else if (this.moveIndex === movesLeft - 1) {
                movesLeft = Math.max(movesLeft - (turn.moves ? turn.moves.length + 1 : 0), 0);
                movesLeftNext = this.moveIndex + 1;
                break;
              }

              movesLeftNext = movesLeft - 1;
              movesLeft = Math.max(movesLeft - (turn.moves ? turn.moves.length + 1 : 0), 0);
            }
          }
          this.handleReplayChange(movesLeftNext || 0, true);
        }
      });
    });
  }

  handleWindowScroll() {
    this.setState({
      windowWidth: window.innerWidth,
      isMobile: window.innerWidth < 600
    });
  }

  // Takes in moveIndex
  handleReplayChange(value, isInitial, callback) {
    const { gameID, turnIndex } = this.props.match.params;
    const { replay, board, currWord, wordList } = this.state;
    if (typeof value !== 'number' ||
        value === 'NaN') {
      return;
    }

    if (replay) {
      this.moveIndex = value;
      let clonedWord = currWord;
      let clonedWordStyle = {};

      // Find closest cached board state
      let clonedBoard;
      let i = this.moveIndex;
      if (this.lastMoveIndex !== null) {
        while (i !== this.lastMoveIndex) {
          if (this.replayCachedMoves.hasOwnProperty(i)) {
            // console.log("FOUND CACHED MOVE", i);
            clonedBoard = JSON.parse(JSON.stringify(this.replayCachedMoves[i].board));
            clonedWord = this.replayCachedMoves[i].currWord;
            clonedWordStyle = this.replayCachedMoves[i].currWordStyle;
            this.lastMoveIndex = i;
            break;
          }

          // Move i towards lastMoveIndex
          if (i < this.lastMoveIndex) i++;
          else i--;
        }
      }
      if (!clonedBoard) {
        clonedBoard = JSON.parse(JSON.stringify(board));
      }

      let updateObj = {
        board: clonedBoard,
        currWord: clonedWord,
        currWordStyle: clonedWordStyle,
        chatMessage: '', // Reset chat
        chatImage: null, // Reset chat
        swapTiles: [],
        wordList: [],
      }
      let currTurnIndex = 0;
      let currMoveIndex = 0;
      let turnOffset = this.replayNumMoves;

      // find currTurnIndex
      ({ currTurnIndex, turnOffset } = findTurnIndex(replay, this.replayNumMoves, this.lastMoveIndex));


      // console.log("HANDLE REPLAY CHANGE", currTurnIndex, turnOffset, this.moveIndex, this.lastMoveIndex);
      if (this.moveIndex >= this.lastMoveIndex) {
        updateObj =
          forwardReplay(
            replay, updateObj, this.lastMoveIndex, this.moveIndex, currTurnIndex, turnOffset);
      } else {
        updateObj =
          backwardReplay(
            replay, updateObj, this.lastMoveIndex, this.moveIndex, currTurnIndex, turnOffset);
      }
      this.lastMoveIndex = this.moveIndex;

      // Find curr move Chat
      ({ currTurnIndex, turnOffset } = findTurnIndex(replay, this.replayNumMoves, this.moveIndex));
      currMoveIndex = this.moveIndex - turnOffset - 1;
      let turnMoves = replay.turns[currTurnIndex].moves;
      let currMove = turnMoves[currMoveIndex];
      if (currMove) {
        if (currMove.text) updateObj.chatMessage = currMove.text;
        else if (currMove.image !== undefined && currMove.image !== null) updateObj.chatImage = currMove.image;
      }

      // Generate Word List on load once only
      if (wordList !== null) {
        delete updateObj.wordList;
      }

      // Find curr turn swap
      let swapIndex = turnMoves.findIndex((move) => { return move.type === ReplayTurn.MOVE_TYPE.SWAP });
      if (turnMoves && turnMoves.length &&
          swapIndex >= 0 && currMove &&
          (currMove.type === ReplayTurn.MOVE_TYPE.SELECT ||
           currMove.type === ReplayTurn.MOVE_TYPE.SWAP)) {
        updateObj.swapTiles = [turnMoves[swapIndex].pos1, turnMoves[swapIndex].pos2];
        if (currMove.type === ReplayTurn.MOVE_TYPE.SWAP) {
          updateObj.currWord = 'SWAP';
          updateObj.currWordStyle = { fontSize: 18};
          // updateObj.currWordStyle = {color: tilesetTheme.BLUE_DARK, fontSize: 18};
        }
      }

      // Set replay cached move
      if (!this.replayCachedMoves.hasOwnProperty(this.moveIndex)) {
        this.replayCachedMoves[this.moveIndex] = {
          board: JSON.parse(JSON.stringify(updateObj.board)),
          currWord: updateObj.currWord,
          currWordStyle: updateObj.currWordStyle
        }
        if (updateObj.swapTiles) {
          this.replayCachedMoves[this.moveIndex].swapTiles = updateObj.swapTiles;
        }
      }

      // Calculate left and right arrow replay indices (magic, unexplainable)
      let movesLeft = this.replayNumMoves;
      let movesLeftNext = this.replayNumMoves;
      if (replay && replay.turns && replay.turns.length) {
        for (var k = replay.turns.length - 1; k >= 0; k--) {
          let turn = replay.turns[k];
          if (this.moveIndex > movesLeft) {
            break;
          } else if (this.moveIndex === movesLeft) {
            movesLeft -= 1;
            break;
          } else if (this.moveIndex === movesLeft - 1) {
            movesLeft = Math.max(movesLeft - (turn.moves ? turn.moves.length + 1 : 0), 0);
            movesLeftNext = this.moveIndex + 1;
            break;
          }

          movesLeftNext = movesLeft - 1;
          movesLeft = Math.max(movesLeft - (turn.moves ? turn.moves.length + 1 : 0), 0);
        }
      }
      updateObj.movesLeft = movesLeft;
      updateObj.movesLeftNext = movesLeftNext;

      // Set Curr Turn Word
      if (!!turnIndex && isInitial) {
        updateObj.currTurnWord = updateObj.currWord;
      }

      // Set state and rerender
      this.setState(updateObj, () => {
        if (typeof callback === 'function') {
          callback();
        }
      })
    }
  }

  renderPlayers() {
    const { turnIndex } = this.props.match.params;
    const { playerOneData, playerTwoData, currTurnIndex } = this.state;
    if (!turnIndex) {
      return null;
    }

    let playerOneEl = null;
    let playerTwoEl = null;
    if (playerOneData && playerTwoData) {
      let playerOneText = `${playerOneData.displayName} played ${this.state.currTurnWord}`;
      let playerTwoText = `against ${playerTwoData.displayName}`;
      if (!turnIndex) {
        playerOneText = `${playerOneData.displayName} played ${this.state.currTurnWord}`;
        playerTwoText = `against ${playerTwoData.displayName}`;
      }
      playerOneEl = (
        <div className="player-one">
          <Avatar icon={playerOneData.icon} />
          <span className="played-text">
            { playerOneText }
          </span>
        </div>
      )
      playerTwoEl = (
        <div className="player-two">
          <span className="played-text">
            { playerTwoText }
          </span>
          <Avatar icon={playerTwoData.icon} />
        </div>
      )
      if (!!turnIndex && turnIndex % 2 === 0) {
        playerTwoText = `${playerTwoData.displayName} played ${this.state.currTurnWord}`;
        playerOneText = `against ${playerOneData.displayName}`;
        playerOneEl = (
          <div className="player-one">
            <Avatar icon={playerTwoData.icon} />
            <span className="played-text">
              { playerTwoText }
            </span>
          </div>
        )
        playerTwoEl = (
          <div className="player-two">
            <span className="played-text">
              { playerOneText }
            </span>
            <Avatar icon={playerOneData.icon} />
          </div>
        )
      }

    }
    return (
      <div className="players-container">
        { playerOneEl }
        { playerTwoEl }
      </div>
    );
  }

  renderScoreCounter() {
    const { flipFirstPlayer } = this.props.match.params;
    const { playerOneScore, playerTwoScore, isMobile } = this.state;
    let playerOneStyle = {
      color: theme.BLUE_DARK,
      marginRight: 60,
    };
    let playerTwoStyle = {
      color: theme.RED_DARK,
      marginLeft: 60,
    };
    let crownWidth = isMobile ? 32 : 48;

    let finalPlayerOneScore = playerOneScore;
    let finalPlayerTwoScore = playerTwoScore;
    if (flipFirstPlayer === 'true') {
      finalPlayerOneScore = playerTwoScore;
      finalPlayerTwoScore = playerOneScore;
    }

    return (
      <div className="score-container">
        <div className="score" style={playerOneStyle}>{finalPlayerOneScore}</div>
        <Crown width={crownWidth} />
        <div className="score" style={playerTwoStyle}>{finalPlayerTwoScore}</div>
      </div>
    )
  }

  renderStats() {
    const { flipFirstPlayer } = this.props.match.params;
    const { wordList, turnIndices, currTurnIndex, isMobile } = this.state;
    if (isMobile) {
      return null;
    }
    return (
      <div className="stats-container">
        <h2>Word List</h2>
        <ReplayWords
          flipFirstPlayer={flipFirstPlayer === 'true'}
          currTurnIndex={currTurnIndex}
          wordList={wordList}
          turnIndices={turnIndices}
          handleReplayChange={this.handleReplayChange.bind(this)}
        />
      </div>
    )
  }

  renderWord() {
    return (
      <div className="word-container">
        {this.state.currWord}
      </div>
    );
  }

  renderGameBoard() {
    const { flipFirstPlayer } = this.props.match.params;
    const { board, userID, playerOne, playerTwo, swapTiles, isMobile, chatImage } = this.state;
    let width = isMobile ? window.innerWidth : window.innerWidth / 2;

    let heightDiff = width * .03;
    let gridWidth = Math.min(width, window.innerHeight - 300); // Max width window height
    let gridHeight = gridWidth + heightDiff;
    let gridOrigin = {x: 0, y: 0};

    if (board) {
      let tileKeys = Object.keys(board);
      let chatEl = null;
      if (chatImage !== null) (
        chatEl = (
          <div className="chat-container">
            <img src={require('./assets/reacts/boom.png')} alt="Boom" />
          </div>
        )
      )
      return (
        <div className="board-container">
          {chatEl}
          <HexGrid width={gridWidth} height={gridHeight}>
            <HexLayout
              size={{ x: 6, y: 6 }}
              spacing={1.1}
              origin={gridOrigin}
            >
              { tileKeys.map((hex, i) => {
                  // Flip perspective
                  let finalTileOwner = board[hex].tileOwner;
                  if (finalTileOwner !== 'neutral' &&
                      flipFirstPlayer === 'true') {
                    if (finalTileOwner === playerOne) {
                      finalTileOwner = playerTwo;
                    } else {
                      finalTileOwner = playerOne;
                    }
                  }
                  return (
                    <Tile
                      key={hex}
                      userID={userID}
                      q={board[hex].q}
                      r={board[hex].r}
                      s={board[hex].s}
                      letter={board[hex].letter}
                      isSelected={board[hex].isSelected}
                      isPerm={board[hex].isPerm}
                      tileOwner={finalTileOwner}

                      board={board}
                      clickedTiles={this.state.clickedTiles}
                      debugMode={this.debugMode}
                      playerOne={playerOne}
                      hasSwapped={swapTiles.length > 0}

                      viewingPlayer={this.viewingPlayer}

                      ratioX={gridWidth / 100}
                      ratioY={gridHeight / 100}
                    />
                  )
                })
              }
              {/*<SwapIndicator swapTiles={swapTiles} />*/}
            </HexLayout>
          </HexGrid>
        </div>
      );
    }

    return null;
  }

  render() {
    const { replay, movesLeft, movesLeftNext } = this.state;

    return (
      <div className="wrapper">
        <div className="left-container">
          { this.renderStats() }
        </div>
        <div className="game-container">
          { this.renderPlayers() }
          <div>
            { this.renderScoreCounter() }
            { this.renderWord() }
            { this.renderGameBoard() }
            <div className="replay-left-arrow" onClick={() => this.handleReplayChange(movesLeft)}>
              <LeftArrow color="#444" />
            </div>
            <div className="replay-right-arrow"  onClick={() => this.handleReplayChange(movesLeftNext)}>
              <RightArrow color="#444" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
