class AxialCoord {

  static directions = [
    new AxialCoord(1, 0), new AxialCoord(1, -1), new AxialCoord(0, -1),
    new AxialCoord(-1, 0), new AxialCoord(-1, 1), new AxialCoord(0, 1)
  ];

  constructor(q, r) {
    this.q = q;
    this.r = r;
  }

  toPosition() {
    let s = -(this.q + this.r);
    return `${this.q},${this.r},${s}`;
  }

  toCubeCoords() {
    let s = -this.q-this.r;
    return {q:this.q, r:this.r, s:s};
  }

  static equal(c1, c2) {
    return c1.q === c2.q && c1.r === c2.r;
  }

  static add(x, y) {
    return new AxialCoord(x.q + y.q, x.r + y.r);
  }

  /* Same logic as in HexUtils.js */
  static neighbor(c, direction) {
    return AxialCoord.add(c, AxialCoord.directions[(6 + (direction % 6)) % 6]);
  }

  static neighbors(c) {
    return AxialCoord.directions.map(x => AxialCoord.add(c, x));
  }

  static randomCoordInHex(radius) {
    var q = 0;
    var r = 0;
    var tries = 0;
    while (tries < 10) {
      q = Math.floor((2*radius+1) * Math.random()) - radius;
      r = Math.floor((2*radius+1) * Math.random()) - radius;
      if (Math.max(-radius, -q - radius) <= r &&
          r <= Math.min(radius, -q + radius)) {
        return new AxialCoord(q, r);
      }
      tries++;
    }
    return new AxialCoord(0, 0);
  }

}

export default AxialCoord;
