class ReplayTurn {
  static MOVE_TYPE = {
    SWAP: 'SWAP',
    SELECT: 'SELECT'
  }

  constructor(uid) {
    this.playerCurrent = uid;
    this.playerOneScore = 0;
    this.playerTwoScore = 0;
    this.moves = [];
    this.changes = null;
  }
}

export default ReplayTurn;
