import firebase from 'firebase';

export default class Utils {
  static toObject(instance) {
    let obj = {};
    for (let key in instance) {
      if (instance.hasOwnProperty(key)) {
        let value = instance[key];
        if (typeof value.toObject === 'function') {
          value = value.toObject();
        }
        obj[key] = value;
      }
    }
    return obj;
  }

  static validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static timestampToDate(timestamp) {
    if (!timestamp) {
      return null;
    }

    if (timestamp.toDate) {
      return timestamp.toDate();
    } else if (timestamp.seconds && timestamp.nanoseconds) {
      return new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
    }

    return null;
  }

  static timestampEqual(time1, time2) {
    return time1 && time2 && time1.seconds === time2.seconds;
  }

  static partTotalToPercent(part, total) {
    if (!part || !total) {
      return 0;
    }
    return Math.round((part / total) * 1000) / 10;
  }

  static monogram(name) {
    return name.charAt(0).toUpperCase();
  }

  static hexToRgbA(hex){
    // Handle fill none
    if (hex === 'none') {
      return 'rgba(0,0,0,0)';
    }

    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if (c.length== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
  }

  static compareGameKeyLastUpdated(data) {
    return (a,b) => {
      let d1 = Utils.getPropertyByKeys(data, ['gameKeys', a, 'lastUpdated']);
      let d2 = Utils.getPropertyByKeys(data, ['gameKeys', b, 'lastUpdated']);
      if (d1 && d2) {
        d1 = Utils.timestampToDate(d1);
        d2 = Utils.timestampToDate(d2);

        if (d1 > d2) {
          return -1;
        }
        return 1;
      }
      return 0;
    }
  }

  static getPropertyByKeys(object, keys) {
    if (object === null) {
      return null;
    }

    for (var i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (object && object.hasOwnProperty(key)) {
        object = object[key];
      } else {
        return null;
      }
    }
    return object;
  }

  static throttle(func, wait, options) {
    console.log("THROTTLE", this);
    var context, args, result;
    var timeout = null;
    var previous = 0;
    options || (options = {});

    const _getTime = Date.now || function () {
      return new Date().getTime();
    };

    var later = function later() {
      previous = options.leading === false ? 0 : _getTime();
      timeout = null;
      result = func.apply(context, args);
      context = args = null;
    };

    return () => {
      var now = _getTime();
      if (!previous && options.leading === false) previous = now;
      var remaining = wait - (now - previous);
      context = options.context || this;
      args = arguments;

      if (remaining <= 0) {
        clearTimeout(timeout);
        timeout = null;
        previous = now;
        result = func.apply(context, args);
        context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }

      return result;
    };
  };

  static async setLocalItem(key, item) {
    try {
      //we want to wait for the Promise returned by AsyncStorage.setItem()
      //to be resolved to the actual value before returning the value
      // let jsonOfItem = await AsyncStorage.setItem(key, JSON.stringify(item));
      // return jsonOfItem;
    } catch (error) {
      console.log(error.message);
    }
  }

  static async getLocalItem(key) {
    try {
      // const retrievedItem =  await AsyncStorage.getItem(key);
      // const item = JSON.parse(retrievedItem);
      // return item;
    } catch (error) {
      console.log(error.message);
    }
    return
  }

  static async clearLocalStorage() {
    // AsyncStorage
    //   .getAllKeys()
    //   .then(keys => {
    //     console.log("GET ALL KEYS", keys);
    //     if (keys && keys.length) {
    //       AsyncStorage.multiRemove(keys);
    //     }
    //   })
  }
}