import React, { Component } from 'react';

export default class Back extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="60" cy="60" r="60" fill="#00A5D9"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <path d="M19.0162 90.6442C18.479 87.1676 31.4354 79.4515 34.1217 80.0309C39.2258 82.0589 39.7012 91.2237 38.358 92.0928C37.0148 92.962 19.5535 94.1208 19.0162 90.6442Z" fill="#B5B1A7"/>
        <path d="M34.1213 45.0739C34.1634 44.8232 34.1619 44.836 34.125 45.0736L52.9641 43.8439C53.7195 48.084 66.2305 54.0308 70.4998 57.5001C75.3687 60.7107 80.3609 66.9315 84.9998 62.2925C85.9998 61.2925 86.1332 43.8774 84.4998 42.1221C83.0018 40.5122 72.7643 34.2507 74.4998 32.5288C76.2353 30.807 84.5164 35.0001 86.4998 35.0001C88.4833 35.0001 94.7153 29.3235 95.4998 30.5002C96.4998 32.0001 91.7477 38.9243 91.4998 42.1221C91.2519 45.3198 96.768 52.6875 100.046 62.2925C105.055 76.9679 99.1846 82.4629 96.3273 84.9228C88.6203 91.5575 56.2892 98.258 47.0138 96.2379C36.8486 94.0241 33.2304 89.1964 31.1461 82.4629C29.2334 76.2838 33.7278 47.6336 34.125 45.0736L34.1213 45.0739Z" fill="#C8C4B9"/>
        <ellipse cx="43.4814" cy="39.0774" rx="12.808" ry="17.0774" fill="#C8C4B9"/>
        <circle cx="42.6946" cy="49.1168" r="2.46515" fill="#F97F9D"/>
        <ellipse cx="36.0386" cy="45.1725" rx="7.14894" ry="5.91637" fill="#A6A299"/>
        <ellipse cx="49.3501" cy="45.1725" rx="7.14894" ry="5.91637" fill="#A6A299"/>
        <path d="M48.8574 41.7212C49.3505 40.9816 53.7877 35.3118 56.9924 38.0234" stroke="#3F3F3F" strokeWidth="0.75"/>
        <path d="M46.6381 39.5026C45.8986 38.27 40.2287 38.27 38.7496 39.5026C37.2705 40.7351 41.4613 43.4468 42.6939 43.4468C43.9264 43.4468 47.3777 40.7351 46.6381 39.5026Z" fill="#3F3F3F"/>
        <path d="M61.2493 82.5241C60.9993 80.7605 58.4995 81.0124 56.7497 81.0124C55.0662 81.0124 52.92 81.0124 52.5369 83.2557C52.0739 84.4051 52 91.5938 52 93.8612C52 96.1286 53.9999 98.396 56.7497 98.8999C59.4994 99.4038 81.2478 97.8922 80.9979 96.8844C80.7479 95.8767 71.2486 90.586 68.2488 89.5783C65.249 88.5705 62.9992 88.8225 61.7493 88.5705C61.4993 87.8147 61.4993 84.2876 61.2493 82.5241Z" fill="#B5B1A7"/>
        <ellipse cx="39.7455" cy="32.1398" rx="1.601" ry="2.66834" fill="#3F3F3F"/>
        <circle cx="40.0503" cy="30.9753" r="0.533668" fill="#FBFBFB"/>
        <ellipse cx="46.1498" cy="32.1398" rx="1.601" ry="2.66834" fill="#3F3F3F"/>
        <circle cx="46.4555" cy="30.9912" r="0.533668" fill="#FBFBFB"/>
        <path d="M48.8574 43.4469C49.3505 42.9539 56.4994 39.256 58.9645 42.7074" stroke="#3F3F3F" strokeWidth="0.75"/>
        <path d="M48.6104 45.2743C51.0074 44.5865 57.4849 43.9399 59.7035 46.6515" stroke="#3F3F3F" strokeWidth="0.75"/>
        <path d="M36.2852 41.7215C35.7473 40.9802 30.9066 35.2971 27.4106 38.0151" stroke="#3F3F3F" strokeWidth="0.75"/>
        <path d="M36.2852 43.2004C35.732 42.7578 27.7112 39.4379 24.9455 42.5364" stroke="#3F3F3F" strokeWidth="0.75"/>
        <path d="M36.7778 45.2789C34.1145 44.5934 26.9172 43.9489 24.4521 46.6516" stroke="#3F3F3F" strokeWidth="0.75"/>
      </svg>
    );
  }
}