import React, { Component } from 'react';

export default class Back extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="60" cy="60" r="60" fill="#69B7FF"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <path d="M42.7702 72.8053C30.4272 70.6289 13.47 77.4364 13.1591 79.1997C12.8482 80.963 27.2613 94.312 38.6247 96.3156C49.988 98.3193 56.3774 91.8705 57.241 86.9725C58.1047 82.0745 55.1131 74.9817 42.7702 72.8053Z" fill="#F99853"/>
        <path d="M21.065 82.7827C21.2414 83.3248 22.2925 85.4799 21.3716 87.6171C18.0926 85.0188 12.9321 80.4883 13.1593 79.1997C13.332 78.2201 18.8864 75.3613 25.7029 73.9371C26.5186 76.2527 22.2019 77.1065 22.1363 77.4782C23.1697 77.7882 26.4035 80.2747 26.3161 80.7703C26.2287 81.2659 21.7464 82.7751 21.065 82.7827Z" fill="#EFD8AB"/>
        <path d="M84.5086 88.127C85.006 80.8656 80.3309 78.1799 77.9436 79.5725C72.7711 95.1894 77.3467 97.0793 79.0377 97.4772C80.7287 97.8751 84.0113 95.3883 84.5086 88.127Z" fill="#F98D3F"/>
        <path d="M48.4377 88.1844C47.9426 80.9218 52.5963 78.2356 54.9727 79.6284C60.1215 95.248 55.5668 97.1383 53.8835 97.5363C52.2003 97.9342 48.9328 95.447 48.4377 88.1844Z" fill="#F98D3F"/>
        <path d="M65.705 95.1895C45.6253 95.1895 53.193 76.0911 54.303 73.3059H78.1161C79.7305 82.1588 85.0784 95.1895 65.705 95.1895Z" fill="#F99853"/>
        <path d="M56.3586 76.0909C54.966 75.4941 51.584 70.7195 52.5787 70.0232C53.8719 66.8401 78.5406 67.7353 80.8285 69.9237C81.3258 76.5883 75.5565 77.583 74.6613 77.7819C74.6613 77.2846 73.766 76.7872 73.2687 76.0909C72.9703 76.4888 68.4941 80.5671 66.9025 81.1639C67.1015 79.274 66.4052 78.2793 65.6094 77.7819C64.5152 77.7819 63.62 78.8761 62.8242 79.3734C62.4264 78.8761 60.3375 77.8814 58.9449 77.2846C57.8308 76.8071 57.1544 75.0962 56.9554 74.3004C56.657 74.201 56.4581 75.2951 56.3586 76.0909Z" fill="#EBCE95"/>
        <path d="M93.8589 51.0242C91.2727 33.1194 75.2578 25.9575 66.3055 25.9575C47.0081 25.9575 37.8568 46.2496 39.2494 51.7205C38.5531 53.9089 36.9284 55.003 35.8673 55.3015C37.061 56.5946 37.061 56.3956 37.9562 56.893C36.7626 57.7882 35.0716 57.9872 33.8779 57.9872C36.1658 61.6676 39.4483 61.2697 39.4483 61.5681C39.4483 61.8665 38.0557 62.5628 37.5584 62.7618C39.5478 65.0695 43.5266 65.6133 45.1181 65.547C48.1354 67.9011 56.5971 72.6094 66.3055 72.6094C78.4409 72.6094 86.0007 67.5364 87.7912 65.547C91.77 65.547 95.351 62.9607 95.351 62.5628C95.351 62.1649 93.3615 61.966 93.66 61.5681C96.0473 61.7671 99.5287 58.6835 98.9319 58.0866C98.4346 58.4845 94.7541 57.2909 94.7541 56.9925C94.7541 56.694 97.2409 55.6993 97.1414 55.3015C97.042 54.9036 95.2515 55.6993 93.8589 51.0242Z" fill="#F99853"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M73.7399 54.7384C75.4697 55.2831 76.9145 55.7381 78.9383 55.5028C79.153 55.4778 79.3976 55.4523 79.6678 55.4242C83.0281 55.0743 90.3489 54.3118 93.4945 48.9722C93.6357 49.6406 93.7576 50.3253 93.8589 51.0265C94.9353 54.6402 96.2495 54.9852 96.8367 55.1393C97.0091 55.1846 97.1189 55.2134 97.1414 55.3037C97.1963 55.5233 96.4637 55.9246 95.796 56.2903C95.2537 56.5874 94.7541 56.861 94.7541 56.9947C94.7541 57.2932 98.4346 58.4868 98.9319 58.0889C99.5287 58.6858 96.0473 61.7694 93.66 61.5704C93.5027 61.7801 93.981 61.9346 94.4686 62.092C94.906 62.2333 95.351 62.377 95.351 62.5651C95.351 62.963 91.77 65.5492 87.7912 65.5492C86.0007 67.5387 78.4409 72.6117 66.3055 72.6117C56.5971 72.6117 48.1354 67.9034 45.1181 65.5492C43.5266 65.6156 39.5478 65.0718 37.5584 62.7641C38.0557 62.5651 39.4483 61.8688 39.4483 61.5704C39.4483 61.5012 39.2716 61.4694 38.9716 61.4155C37.9786 61.2371 35.6349 60.8159 33.8779 57.9895C35.0716 57.9895 36.7626 57.7905 37.9562 56.8953C37.7592 56.7858 37.6055 56.7101 37.4729 56.6447C37.0032 56.4132 36.7983 56.3122 35.8673 55.3037C36.9284 55.0053 38.5531 53.9111 39.2494 51.7228C39.0284 50.8548 39.0729 49.6136 39.3812 48.1396C39.4067 48.1854 39.4326 48.2317 39.4587 48.2786C40.711 50.5254 42.6529 54.0095 48.1023 54.6076C52.7351 55.116 55.3142 54.6618 58.0814 54.1744C60.1862 53.8038 62.3997 53.4139 65.7086 53.4139C69.5343 53.4139 71.8138 54.1318 73.7399 54.7384Z" fill="#EFD8AB"/>
        <path d="M47.6049 18.0574C44.7401 18.7736 37.6578 32.5802 42.6313 42.6267C48.1685 38.3495 59.1236 29.5165 58.6461 28.4024C58.0493 27.0098 51.1858 17.1622 47.6049 18.0574Z" fill="#F99853"/>
        <path d="M85.6127 18.0575C88.5018 18.7749 95.6442 32.6051 90.6285 42.6688C85.0442 38.3842 73.9961 29.5361 74.4777 28.4202C75.0796 27.0252 82.0013 17.1608 85.6127 18.0575Z" fill="#F99853"/>
        <path d="M63.8774 96.8773C64.2985 95.7381 61.3273 85.9276 60.7496 81.3073C60.5478 79.6935 56.0075 78.6492 54.595 79.6935C51.4086 82.0491 53.0734 90.3602 54.595 94.0292C56.2093 97.9218 63.0702 99.061 63.8774 96.8773Z" fill="#F98D3F"/>
        <path d="M68.3355 96.8773C67.9219 95.7381 70.84 85.9276 71.4074 81.3073C71.6056 79.6935 76.0648 78.6492 77.4521 79.6935C80.5816 82.0491 78.9466 90.3602 77.4521 94.0292C75.8666 97.9218 69.1282 99.061 68.3355 96.8773Z" fill="#F98D3F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M70.455 54.9935C70.1819 55.3039 69.834 55.6994 69.1896 55.6994C67.8969 55.6994 67.3003 55.401 67.3003 54.2073C67.9964 54.2073 68.593 53.4115 68.593 52.9142C68.593 52.4168 68.1953 51.4221 66.5048 51.4221V56.0973C66.6705 56.3294 67.3799 56.7936 68.8913 56.7936C70.7806 56.7936 71.9739 55.4009 71.2778 54.8041C70.9287 54.4549 70.7297 54.6812 70.455 54.9935ZM62.5547 54.9936C62.8277 55.304 63.1756 55.6995 63.82 55.6995C65.1127 55.6995 65.7093 55.4011 65.7093 54.2074C65.0132 54.2074 64.4166 53.4116 64.4166 52.9143C64.4166 52.4169 64.8144 51.4222 66.5048 51.4222V56.0974C66.3391 56.3295 65.6297 56.7937 64.1183 56.7937C62.229 56.7937 61.0357 55.401 61.7318 54.8042C62.0809 54.455 62.2799 54.6813 62.5547 54.9936Z" fill="#232323"/>
        <ellipse cx="55.066" cy="48.3386" rx="3.0836" ry="4.07831" fill="#232323"/>
        <ellipse cx="77.944" cy="48.3386" rx="3.0836" ry="4.07831" fill="#232323"/>
        <circle cx="53.9715" cy="46.6476" r="0.795767" fill="white"/>
        <circle cx="76.8495" cy="46.6476" r="0.795767" fill="white"/>
      </svg>
    );
  }
}