import ReplayTurn from './models/ReplayTurn';

const _updateReplayScore = (replay, updateObj, currTurnIndex, currMoveIndex, numMoves) => {
  // console.log("UPDATE REPLAY SCORE", currMoveIndex, currTurnIndex, numMoves);
  if (currMoveIndex === numMoves) {
    updateObj.playerOneScore = replay.turns[currTurnIndex].playerOneScore;
    updateObj.playerTwoScore = replay.turns[currTurnIndex].playerTwoScore;
  } else if (currTurnIndex > 0) {
    updateObj.playerOneScore = replay.turns[currTurnIndex - 1].playerOneScore;
    updateObj.playerTwoScore = replay.turns[currTurnIndex - 1].playerTwoScore;
  } else {
    updateObj.playerOneScore = 0;
    updateObj.playerTwoScore = 0;
  }
  return updateObj;
}

// Step forward in replay from start to end (recursive)
export function forwardReplay(replay, updateObj, start, end, currTurnIndex, turnOffset) {
  let board = updateObj.board;
  let currTurn = replay.turns[currTurnIndex]
  let currMoveIndex = start - turnOffset;
  let numMoves = currTurn.moves.length + 1;

  // console.log("FORWARD REPLAY", start, end, currMoveIndex, numMoves);
  if (start >= end) {
    // Update Score at the end
    updateObj = _updateReplayScore(replay, updateObj, currTurnIndex, currMoveIndex, numMoves);
    updateObj.currTurnIndex = currTurnIndex;
    return updateObj;
  }

  if (currMoveIndex >= numMoves) {
    // Advance to next turn
    return forwardReplay(
      replay, updateObj, start, end,
      currTurnIndex + 1,
      turnOffset + numMoves);

  } else if (currMoveIndex + 1 === numMoves) {
    // Apply changes
    // remove selects then apply changes
    for (let i = 0; i < currTurn.moves.length; i++) {
      let move = currTurn.moves[i];
      if (move.type === ReplayTurn.MOVE_TYPE.SELECT) {
        board[move.pos].isSelected = false;
        board[move.pos].tileOwner = move.oldOwner;
      }
    }
    updateObj.currWord = '';
    for (let key in currTurn.changes) {
      board[key] = Object.assign(board[key], currTurn.changes[key].newValue);
    }
  } else {
    // Apply move
    let currMove = currTurn.moves[currMoveIndex];

    if (currMove.type === ReplayTurn.MOVE_TYPE.SWAP) {
      // swap
      let temp = board[currMove.pos1];
      board[currMove.pos1] = board[currMove.pos2];
      board[currMove.pos2] = temp;
    } else if (currMove.type === ReplayTurn.MOVE_TYPE.SELECT) {
      // select
      board[currMove.pos].isSelected = true;
      board[currMove.pos].tileOwner = currTurn.playerCurrent;
      updateObj.currWord += board[currMove.pos].letter;
    }
  }

  // next move
  return forwardReplay(replay, updateObj, start + 1, end, currTurnIndex, turnOffset);
}

// Step backward in replay from start to end (recursive)
export function backwardReplay(replay, updateObj, start, end, currTurnIndex, turnOffset) {
  let board = updateObj.board;
  let currTurn = replay.turns[currTurnIndex]
  let currMoveIndex = start - turnOffset - 1;
  let numMoves = currTurn.moves.length + 1;

  if (start <= end) {
    // Update Score at the end
    updateObj = _updateReplayScore(replay, updateObj, currTurnIndex, currMoveIndex, numMoves);
    updateObj.currTurnIndex = currTurnIndex;
    return updateObj;
  }

  // console.log("BACKWARD REPLAY", start, end, currTurn);
  if (currMoveIndex < 0) {
    // Advance to next turn
    let offset = replay.turns[currTurnIndex - 1].moves.length + 1;
    return backwardReplay(
      replay, updateObj, start, end,
      currTurnIndex - 1,
      turnOffset - offset);

  } else if (currMoveIndex + 1 === numMoves) {
    // Unapply changes
    // unapply changes then add selects
    for (let key in currTurn.changes) {
      board[key] = Object.assign(board[key], currTurn.changes[key].oldValue);
    }
    for (let i = 0; i < currTurn.moves.length; i++) {
      let move = currTurn.moves[i];
      if (move.type === ReplayTurn.MOVE_TYPE.SELECT) {
        board[move.pos].isSelected = true;
        board[move.pos].tileOwner = currTurn.playerCurrent;
        updateObj.currWord += board[move.pos].letter;
      }
    }
    updateObj.wordList.unshift(updateObj.currWord);
  } else {
    // Apply move
    let currMove = currTurn.moves[currMoveIndex];
    if (!currMove) {
      // undo first move of next turn
      currMove = replay.turns[currTurnIndex + 1].moves[0];
    }

    if (currMove.type === ReplayTurn.MOVE_TYPE.SWAP) {
      // swap
      let temp = board[currMove.pos1];
      board[currMove.pos1] = board[currMove.pos2];
      board[currMove.pos2] = temp;
    } else if (currMove.type === ReplayTurn.MOVE_TYPE.SELECT) {
      // deselect
      board[currMove.pos].isSelected = false;
      board[currMove.pos].tileOwner = currMove.oldOwner;
      updateObj.currWord = updateObj.currWord.slice(0, -1);
    }
  }

  // next move
  return backwardReplay(replay, updateObj, start - 1, end, currTurnIndex, turnOffset);
}

// Find turn index from replay and moveIndex
export function findTurnIndex(replay, replayNumMoves, moveIndex) {
  let currTurnIndex = 0;
  let turnOffset = replayNumMoves;
  for (let i = replay.turns.length - 1; i >= 0; i--) {
    let len = replay.turns[i].moves.length + 1;
    turnOffset -= len;
    if (moveIndex > turnOffset) {
      currTurnIndex = i;
      break;
    }
  }
  return { currTurnIndex, turnOffset };
}
