import React, { Component } from 'react';

export default class Back extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="60" cy="60" r="60" fill="#296DAB"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <path d="M17.3794 64.5001C18.9018 64.2267 20.517 66.1333 20.8794 67.5001C21.3869 69.4137 17.0995 73.1601 15.5772 72.3401C14.0549 71.52 15.8571 64.7734 17.3794 64.5001Z" fill="#70EDED"/>
        <path d="M62.9437 34.3084C52.8791 27.8147 39.3791 26.3148 27.3791 35.3147C11.2273 50.0413 10.622 76.9046 35.8313 85.1388C61.0406 93.373 85.5964 80.6522 102.223 73.2626C105.179 76.799 112.516 82.2885 113.149 78.3298C114.112 72.3124 107.66 70.5178 105.073 69.6205C105.918 66.6646 108.05 58.1559 102.223 57.9026C96.3959 57.6492 98.7394 65.9257 100.64 70.0956C96.2058 72.8825 90.4524 73.0514 88.13 72.7875C71.1865 69.4622 79.9552 45.2844 62.9437 34.3084Z" fill="#BBFFFF"/>
        <ellipse cx="29.5642" cy="54.2605" rx="2.8503" ry="3.95875" fill="#232323"/>
        <circle cx="30.3562" cy="52.677" r="0.79175" fill="white"/>
        <ellipse cx="39.6989" cy="54.2605" rx="2.8503" ry="3.95875" fill="#232323"/>
        <circle cx="40.491" cy="52.677" r="0.79175" fill="white"/>
        <path d="M31.7812 63.9199C31.7812 65.2598 32.7113 67.4036 34.6316 67.4036C36.5518 67.4036 37.4819 65.2598 37.4819 63.9199" stroke="black" strokeWidth="1.5" strokeLinecap="round"/>
        <path d="M60.6479 66.5057C59.1255 66.2324 57.2045 67.0525 56.842 68.4193C56.3346 70.3329 60.6479 74.1599 62.1702 73.3398C63.6925 72.5197 62.1702 66.7791 60.6479 66.5057Z" fill="#70EDED"/>
        <ellipse cx="24.9719" cy="59.1695" rx="2.37525" ry="1.9002" fill="#FFB2B2"/>
        <ellipse cx="44.2913" cy="59.1695" rx="2.37525" ry="1.9002" fill="#FFB2B2"/>
      </svg>
    );
  }
}