import React, { Component } from 'react';

export default class Back extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="20" cy="20" r="20" fill="#128783"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
          <circle cx="20" cy="20" r="20" fill="#FF7171"/>
        </mask>
        <g mask="url(#mask0)">
          <circle cx="15.3011" cy="27.6215" r="14.2763" transform="rotate(20.0169 15.3011 27.6215)" fill="#01C2BB"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M29.5335 28.7843C25.9057 33.9023 19.1805 36.1847 13.0014 33.9336C6.8223 31.6826 3.14108 25.6089 3.65569 19.3566C2.93399 20.3748 2.33488 21.5052 1.88706 22.7345C-0.811812 30.1428 3.00594 38.3363 10.4142 41.0352C17.8225 43.734 26.016 39.9163 28.7149 32.508C29.1627 31.2788 29.4311 30.028 29.5335 28.7843Z" fill="#A0E8E6"/>
          <circle cx="13.5145" cy="15.3516" r="5.0387" transform="rotate(20.0169 13.5145 15.3516)" fill="white"/>
          <circle cx="24.5614" cy="19.376" r="5.0387" transform="rotate(20.0169 24.5614 19.376)" fill="white"/>
          <circle cx="13.227" cy="16.1407" r="2.51935" transform="rotate(20.0169 13.227 16.1407)" fill="#55516C"/>
          <circle cx="24.2738" cy="20.1651" r="2.51935" transform="rotate(20.0169 24.2738 20.1651)" fill="#55516C"/>
        </g>
      </svg>
    );
  }
}