import Hex from '../HexGrid/models/Hex';
import AxialCoord from './AxialCoord'
import utils from '../utils.js';

class Board {
  constructor(boardSize) {
    this.createBoard(boardSize);
  }

  toObject() {
    return utils.toObject(this);
  }

  static keyFromCubeCoords(q, r, s) {
    let qStr = q.toString();
    let rStr = r.toString();
    let sStr = s.toString();
    return (qStr + ',' + rStr + ',' + sStr);
  }

  static keyFromAxialCoords(q, r) {
    let s = -q-r;
    return Board.keyFromCubeCoords(q,r,s);
  }

  static keyToAxialCoord(key) {
    let coords = key.split(',');
    return new AxialCoord(Number(coords[0]), Number(coords[1]));
  }

  static positionToString(hex) {
    return Board.keyFromCubeCoords(hex.q, hex.r, hex.s);
  }


  static stringToPosition(positionString) {
    let coords = positionString.split(',');
    let q = parseInt(coords[0]);
    let r = parseInt(coords[1]);
    let s = parseInt(coords[2]);
    let hex = new Hex(q,r,s);

    return (hex)
  }

}

export default Board;
