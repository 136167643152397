import React, { Component } from 'react';

export default class RightArrow extends Component {
  render() {
    const { color } = this.props;

    return (
      <svg height="30" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 19.1258V0.874208C0 0.4875 0.420193 0.247172 0.75351 0.443241L16.2674 9.56903C16.596 9.76236 16.596 10.2376 16.2674 10.431L0.75351 19.5568C0.420193 19.7528 0 19.5125 0 19.1258Z" fill={color}/>
      </svg>
    );
  }
}