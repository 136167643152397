import React from 'react';
import './App.css';

export default class App extends React.Component {
  constructor(props) {
    super();

    this.state = {
    }
  }


  render() {
    return (
      <div>
        <h1>HOME</h1>
      </div>
    );
  }
}
