import React, { Component } from 'react';

export default class Giraffe extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="20" cy="20" r="20" fill="#72D7A6"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <mask id="mask0" maskType="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
          <circle cx="20" cy="20" r="20" fill="#75DA65"/>
        </mask>
        <g mask="url(#mask0)">
          <path d="M10.7674 12.3753C9.329 15.2296 7.4186 33.3483 7 42.2774L21.4419 47.2611L20.186 20.4738C24.5814 20.4738 34 19.2279 34 14.8672C34 9.8835 21.4419 8.01466 16.4186 8.63758C13.2791 9.26058 12.0233 9.8835 10.7674 12.3753Z" fill="#FFCC00"/>
          <mask id="mask1" maskType="alpha" maskUnits="userSpaceOnUse" x="7" y="8" width="27" height="40">
            <path d="M10.7674 12.3753C9.329 15.2296 7.4186 33.3483 7 42.2774L21.4419 47.2611L20.186 20.4738C24.5814 20.4738 34 19.2279 34 14.8672C34 9.8835 21.4419 8.01466 16.4186 8.63758C13.2791 9.26058 12.0233 9.8835 10.7674 12.3753Z" fill="#FFCC00"/>
          </mask>
          <g mask="url(#mask1)">
            <path d="M12.2828 18.5C11.6958 17.9131 11.1088 17.3261 8.76101 19.087C7.9784 20.4566 6.29579 24.0174 5.82623 27.3044C5.35666 30.5913 8.76101 31.4131 10.5219 31.4131C11.6958 31.4131 16.3914 28.4783 13.4567 24.9566C10.5219 21.4348 12.8697 19.087 12.2828 18.5Z" fill="#CF8B24"/>
            <path d="M12.2827 37.8696C11.1088 34.3479 8.17398 34.9349 6.41311 35.5218C5.6305 37.087 4.18268 40.9218 4.65224 43.7392C5.2392 47.261 15.8044 49.0218 13.4566 46.674C11.1088 44.3262 13.4566 41.3914 12.2827 37.8696Z" fill="#CF8B24"/>
            <path d="M22.9395 31.2701C21.7656 27.7483 18.8724 24.5131 17.7902 30.2394C17.7901 33.7611 15.5597 35.6394 16.0293 38.4568C16.6163 41.9785 26.4612 42.4222 24.1134 40.0744C21.7656 37.7266 24.1134 34.7918 22.9395 31.2701Z" fill="#CF8B24"/>
          </g>
          <path d="M13.0904 5.72038C12.5457 6.0668 12.1588 8.56437 12.6271 9.76752C13.406 10.0227 14.5743 10.4054 15.1191 10.059C15.6638 9.71258 15.4271 6.48586 15.1929 5.88428C14.9588 5.2827 13.6351 5.37396 13.0904 5.72038Z" fill="#CF8B24"/>
          <ellipse cx="13.343" cy="10.6719" rx="2.34783" ry="3.52174" transform="rotate(-50.5422 13.343 10.6719)" fill="#FBD645"/>
          <ellipse cx="21.0871" cy="12.6306" rx="2.34783" ry="2.93478" fill="white"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M20.0427 11.8245C19.9598 12.066 19.9129 12.3399 19.9129 12.6302C19.9129 13.6027 20.4385 14.3911 21.0868 14.3911C21.7352 14.3911 22.2607 13.6027 22.2607 12.6302C22.2607 11.6577 21.7352 10.8694 21.0868 10.8694C20.9767 10.8694 20.8702 10.8921 20.7691 10.9346C20.9578 11.0322 21.0868 11.2292 21.0868 11.4563C21.0868 11.7805 20.824 12.0433 20.4999 12.0433C20.3151 12.0433 20.1503 11.9579 20.0427 11.8245Z" fill="#01C2BB"/>
          <mask id="mask2" maskType="alpha" maskUnits="userSpaceOnUse" x="7" y="8" width="27" height="40">
            <path d="M10.7674 12.3753C9.329 15.2296 7.4186 33.3483 7 42.2774L21.4419 47.2611L20.186 20.4738C24.5814 20.4738 34 19.2279 34 14.8672C34 9.8835 21.4419 8.01466 16.4186 8.63758C13.2791 9.26058 12.0233 9.8835 10.7674 12.3753Z" fill="#FFCC00"/>
          </mask>
          <g mask="url(#mask2)">
            <rect x="26.3037" y="8.52197" width="8.22305" height="11.5002" transform="rotate(2.74574 26.3037 8.52197)" fill="#FBD645"/>
          </g>
        </g>
      </svg>
    );
  }
}