import React, { Component } from 'react';
import PropTypes from 'prop-types';

// TODO Text is a separate component so that it could wrap the given text inside the surrounding hexagon
class HexText extends Component {
  static propTypes = {
    children: PropTypes.string,
    x: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    y: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    className: PropTypes.string
  };

  render() {
    const { children, x, y, className } = this.props;

    return (
      <text x={x || 0} y={y ? y + 1.5 : '0.3em'} textAnchor="middle" style={styles.text} pointerEvents="none" disabled={true}>{children}</text>
    );
  }
}

export default HexText;

const styles = {
  text: {
    fontSize: 5
  },
};
