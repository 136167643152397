import React from 'react';
import theme from '../theme.style.js';
// import './ReplayWords.css';

export default class ReplayWords extends React.Component {
  constructor(props) {
    super();

    this.state = {
    }
  }

  renderWordList() {
    const { flipFirstPlayer, wordList, turnIndices, currTurnIndex, handleReplayChange } = this.props;
    if (wordList) {
      let words = wordList.map((word, i) => {
        let color = i % 2 ? theme.RED_DARK : theme.BLUE_DARK;
        if (flipFirstPlayer) color = i % 2 ? theme.BLUE_DARK : theme.RED_DARK;
        let style = {
          color: color
        }
        if (currTurnIndex === i) {
          style.borderRadius = '22px';
          style.color = '#fff';
          style.backgroundColor = color;
        }
        return (
          <div className="replay-word-container">
            <div
              className="word"
              style={style}
              onClick={() => handleReplayChange(turnIndices[i] - 1)}
            >
              {word}
            </div>
          </div>
        )
      })
      return words;
    }
  }

  render() {
    return (
      <div className="replay-words-container">
        { this.renderWordList() }
      </div>
    );
  }
}
