import React, { Component } from 'react';

export default class Crown extends Component {
  render() {
    const { width } = this.props;
    let finalWidth = width || 48;
    return (
      <svg width={finalWidth} viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.5 33H0.5C0.223858 33 0 32.7761 0 32.5V1.08007C0 0.654052 0.498692 0.423191 0.823514 0.698834L8.56448 7.26782C8.7737 7.44536 9.0869 7.42113 9.26631 7.21352L15.1217 0.437776C15.3211 0.207009 15.6789 0.207009 15.8783 0.437776L21.7337 7.21351C21.9131 7.42112 22.2263 7.44536 22.4355 7.26782L30.1765 0.698834C30.5013 0.423191 31 0.654051 31 1.08007V32.5C31 32.7761 30.7761 33 30.5 33Z" fill="#FFCC00"/>
        <path d="M9.47021 27H11.7562V13.77L6.64421 15.678V17.82L9.47021 16.776V27ZM19.9047 13.968L17.0427 18.468C15.8907 20.268 15.4407 21.438 15.4407 22.77C15.4407 25.488 17.4567 27.198 20.0667 27.198C22.7487 27.198 24.7107 25.398 24.7107 22.968C24.7107 20.628 22.9287 18.972 20.7507 18.972C20.0667 18.972 19.4727 19.17 19.0587 19.422L19.0227 19.386L22.5507 13.968H19.9047ZM17.7627 22.95C17.7627 21.69 18.8247 20.79 20.1387 20.79C21.4887 20.79 22.4607 21.762 22.4607 23.022C22.4607 24.354 21.3807 25.236 20.1027 25.236C18.6987 25.236 17.7627 24.12 17.7627 22.95Z" fill="white"/>
      </svg>
    );
  }
}