import React, { Component } from 'react';

export default class LeftArrow extends Component {
  render() {
    const { color } = this.props;
    return (
      <svg height="30" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 0.874208L17 19.1258C17 19.5125 16.5798 19.7528 16.2465 19.5568L0.732645 10.431C0.403992 10.2376 0.403992 9.76236 0.732645 9.56903L16.2465 0.443241C16.5798 0.247171 17 0.487499 17 0.874208Z" fill={color}/>
      </svg>
    );
  }
}