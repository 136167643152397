import React, { Component } from 'react';

export default class Back extends Component {
  render() {
    const { radius, transparent } = this.props;
    let finalRadius = radius || 40;
    let circleBg = <circle cx="60" cy="60" r="60" fill="#DDFBEB"/>
    if (transparent) {
      circleBg = null;
    }
    return (
      <svg width={finalRadius} height={finalRadius} viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        { circleBg }
        <path d="M88.9509 81.8449C91.4872 81.3977 92.425 79.3012 92.0398 77.1161C91.7476 75.4594 89.5013 74.3323 88.0229 72.1325C86.9385 70.4319 87.1214 69.6151 86.8043 69.671C86.4873 69.7269 86.9385 70.4319 86.5012 72.4008C85.8514 75.1137 84.1338 76.7717 84.4311 78.4577C84.8163 80.6428 86.4147 82.2921 88.9509 81.8449Z" fill="#FF7171"/>
        <rect x="40.7139" y="74.8694" width="6" height="23" rx="3" transform="rotate(15 40.7139 74.8694)" fill="#FFC989"/>
        <rect x="58.1006" y="79.5283" width="6" height="23" rx="3" transform="rotate(15 58.1006 79.5283)" fill="#FFC989"/>
        <path d="M46.1169 27.6593C47.5463 22.3246 53.0297 19.1588 58.3643 20.5882C63.699 22.0176 66.8648 27.501 65.4354 32.8356L53.5297 77.2682C52.958 79.4021 50.7646 80.6684 48.6307 80.0967L37.0396 76.9908C34.9058 76.4191 33.6394 74.2257 34.2112 72.0918L46.1169 27.6593Z" fill="#FF7171"/>
        <path d="M63.5036 32.318C64.933 26.9833 70.4164 23.8175 75.7511 25.2469C81.0857 26.6763 84.2515 32.1597 82.8221 37.4943L70.9164 81.9269C70.3447 84.0608 68.1513 85.3271 66.0175 84.7553L54.4264 81.6495C52.2925 81.0777 51.0262 78.8844 51.5979 76.7505L63.5036 32.318Z" fill="#FF7171"/>
        <path opacity="0.1" d="M47.6512 37.3878C48.5088 34.187 51.7989 32.2875 54.9997 33.1452C58.2005 34.0029 60.0999 37.2929 59.2423 40.4937L50.9601 71.4033C50.3883 73.5372 48.195 74.8035 46.0611 74.2317L42.1974 73.1964C40.0635 72.6247 38.7972 70.4313 39.369 68.2975L47.6512 37.3878Z" fill="white"/>
        <path opacity="0.1" d="M65.0379 42.0465C65.8956 38.8457 69.1856 36.9462 72.3864 37.8039C75.5872 38.6615 77.4867 41.9516 76.629 45.1524L68.3468 76.062C67.775 78.1959 65.5817 79.4622 63.4478 78.8904L59.5841 77.8551C57.4503 77.2834 56.1839 75.09 56.7557 72.9562L65.0379 42.0465Z" fill="white"/>
      </svg>
    );
  }
}