import React from 'react';
import Cookie from '../assets/user-icons/Cookie';
import Giraffe from '../assets/user-icons/Giraffe';
import Frog from '../assets/user-icons/Frog';
import Ghost from '../assets/user-icons/Ghost';
import Walrus from '../assets/user-icons/Walrus';
import Fox from '../assets/user-icons/Fox';
import Popsicle from '../assets/user-icons/Popsicle';
import Whale from '../assets/user-icons/Whale';

export default class AvatarIcon extends React.PureComponent {
  constructor(props) {
    super();
  }

  render() {
    const { radius, icon, transparent } = this.props;

    let finalRadius = radius || 40;
    let element = null;

    // render icon
    if (icon === 'COOKIE') {
      element = <Cookie radius={finalRadius} transparent={transparent} />
    } else if (icon === 'GIRAFFE') {
      element = <Giraffe radius={finalRadius} transparent={transparent} />
    } else if (icon === 'FROG') {
      element = <Frog radius={finalRadius} transparent={transparent} />
    } else if (icon === 'GHOST') {
      element = <Ghost radius={finalRadius} transparent={transparent} />
    } else if (icon === 'WALRUS') {
      element = <Walrus radius={finalRadius} transparent={transparent} />
    } else if (icon === 'FOX') {
      element = <Fox radius={finalRadius} transparent={transparent} />
    } else if (icon === 'POPSICLE') {
      element = <Popsicle radius={finalRadius} transparent={transparent} />
    } else if (icon === 'WHALE') {
      element = <Whale radius={finalRadius} transparent={transparent} />
    } else { // DEFAULT ICON
      element = <Ghost radius={finalRadius} transparent={transparent} />
    }

    return element;
  }
}